import React from 'react'
import clsx from 'clsx'
import {useLayout} from '../../../_metronic/layout/core'

interface PaginationProps {
  pageNumber: number
  totalPages: number
  onChange: (page: number) => void
}

const CustomPagination: React.FC<PaginationProps> = ({pageNumber, totalPages, onChange}) => {
  const {languageDirection}: any = useLayout()

  const handlePageChange = (page: number) => {
    onChange(page)
  }

  const renderPageButtons = (start: number, end: number) => {
    const buttons = []

    for (let i = start; i <= end; i++) {
      buttons.push(
        <li className={clsx('page-item', {active: pageNumber === i})} key={i}>
          <button onClick={() => handlePageChange(i)} className='page-link'>
            {i}
          </button>
        </li>
      )
    }

    return buttons
  }

  // Number of pages to display in each group
  const pagesPerGroup = 10

  // Calculate the current group of pages
  const currentPageGroup = Math.ceil(pageNumber / pagesPerGroup)

  // Calculate the start and end page numbers for the current group
  const startPage = (currentPageGroup - 1) * pagesPerGroup + 1
  const endPage = Math.min(currentPageGroup * pagesPerGroup, totalPages)

  // Calculate whether previous and next group arrows should be disabled
  const isPreviousDisabled = currentPageGroup === 1
  const isNextDisabled = endPage === totalPages

  return (
    <div className='flex items-end m-4'>
      <ul className='pagination'>
        <li className={clsx('page-item previous', {disabled: isPreviousDisabled})}>
          <button
            onClick={() => handlePageChange(startPage - 1)}
            disabled={isPreviousDisabled}
            className='page-link'
          >
            {languageDirection === 'ltr' ? <i className='previous'></i> : <i className='next'></i>}
          </button>
        </li>
        {renderPageButtons(startPage, endPage)}
        <li className={clsx('page-item next', {disabled: isNextDisabled})}>
          <button
            onClick={() => handlePageChange(endPage + 1)}
            disabled={isNextDisabled}
            className='page-link'
          >
            {languageDirection !== 'ltr' ? <i className='previous'></i> : <i className='next'></i>}
          </button>
        </li>
      </ul>
    </div>
  )
}

export default CustomPagination
