import {EnableSidebar, PageTitle} from '../../../_metronic/layout/core'
// import {
//   // ListsWidget4,
//   // ListsWidget5,
//   // TablesWidget9,
//   MixedWidget13,
//   MixedWidget14,
//   // MixedWidget15,
// } from '../../../_metronic/partials/widgets'
import {MixedWidget13} from './components/MixedWidget13'
import {MixedWidget14} from './components/MixedWidget14'
import {OrderList} from './OrderList'
import {PaidExpiredOrderList} from './PaidExpiredOrderList'
// import {useAuth} from '../../../app/modules/auth'
import {useLayout} from '../../../_metronic/layout/core'
import {getTranslation} from '../../../app/utility/Translation'

const DashboardPage = () => (
  <div style={{justifyContent: 'center', margin: 'auto', width: '90%'}}>
    <div className='row gy-5 g-xl-10'>
      {/*begin::Col*/}
      <div className='col-xl-12'>
        <MixedWidget13
          className='card-xl-stretch mb-xl-10'
          backGroundColor='#F7D9E3'
          chartHeight='200px'
        />
      </div>
      {/*end::Col*/}

      {/*begin::Col*/}
      <div className='col-xl-12'>
        <MixedWidget14
          className='card-xl-stretch mb-xl-10'
          backGroundColor='#CBF0F4'
          chartHeight='200px'
        />
      </div>
      {/*end::Col*/}

      {/*begin::Col*/}
      {/* <div className='col-xl-4'>
        <MixedWidget15 className='card-xl-stretch mb-xl-10' backGroundColor='#CBD4F4' />
      </div> */}
      {/*end::Col*/}
    </div>
    {/*end::Row*/}
    <OrderList className='mb-5 mb-xl-8' />
    <PaidExpiredOrderList className='mb-5 mb-xl-8' />
    {/* <TablesWidget9 className='mb-5 mb-xl-10' /> */}

    {/*begin::Row*/}
    <div className='row gy-5 g-xl-10'>
      {/*begin::Col*/}
      {/* <div className='col-xxl-6'>
        <ListsWidget5 className='card-xl-stretch mb-xl-10' />
      </div> */}
      {/*end::Col*/}

      {/*begin::Col*/}
      {/* <div className='col-xxl-6'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-10' items={5} />
      </div> */}
      {/*end::Col*/}
    </div>
    <br />
    <br />
  </div>
)

const DashboardWrapper = () => {
  // const {currentUser} = useAuth()
  const {languageDirection}: any = useLayout()
  return (
    <EnableSidebar>
      <PageTitle description='Welcome back' breadcrumbs={[]}>
        {/* {currentUser?.userName} */}
        {getTranslation('hello', languageDirection)}
      </PageTitle>
      <DashboardPage />
    </EnableSidebar>
  )
}

export {DashboardWrapper}
