import * as Constants from '../../../config/constants'

const OrderStatus = [
  {
    name: Constants.COMPLETED.completed,
    value: Object.keys(Constants.COMPLETED)[0],
  },
  {
    name: Constants.OCCUPIED.occupied,
    value: Object.keys(Constants.OCCUPIED)[0],
  },
  {
    name: Constants.WAITING_FOR_PAYMENT.waiting_for_payment,
    value: Object.keys(Constants.WAITING_FOR_PAYMENT)[0],
  },
  {
    name: Constants.PAYMENT_SUCCESS.payment_success,
    value: Object.keys(Constants.PAYMENT_SUCCESS)[0],
  },
  {
    name: Constants.PAYMENT_SUCCESS.payment_success,
    value: 'payment_true',
  },
  {
    name: Constants.ORDER_EXPIRED.order_expired,
    value: Object.keys(Constants.ORDER_EXPIRED)[0],
  },
  {
    name: Constants.PAID_AND_EXPIRED.paid_and_expired,
    value: Object.keys(Constants.PAID_AND_EXPIRED)[0],
  },
  {
    name: Constants.ORDER_CANCELLED.order_cancelled,
    value: Object.keys(Constants.ORDER_CANCELLED)[0],
  },
  {
    name: Constants.PAYMENT_RETRY.retry_payment,
    value: Object.keys(Constants.PAYMENT_RETRY)[0],
  },
  {
    name: Constants.PAYMENT_Failed.payment_failed,
    value: Object.keys(Constants.PAYMENT_Failed)[0],
  },
  {
    name: Constants.PAYMENT_Failed.payment_failed,
    value: 'payment_false',
  },
]
const OrderType = [
  {
    name: Constants.TKRMORDER.tkrmOrder,
    value: Object.keys(Constants.TKRMORDER)[0],
  },
  {
    name: Constants.ZAKATORDER.zakatOrder,
    value: Object.keys(Constants.ZAKATORDER)[0],
  },
]
const getFilterOrderType = (text) => {
  const filter = OrderType.filter((status) => {
    return status.value === text
  })
  return filter[0].name
}
const getFilterStatus = (text) => {
  const filter = OrderStatus.filter((status) => {
    return status.value === text
  })
  return filter[0].name
}
export {getFilterOrderType, getFilterStatus}
