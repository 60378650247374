const data = {
  resources: {
    user_name: ['User Name', 'اسم المستخدم'],
    mobile_no: ['Mobile No', 'رقم الجوال'],
    password: ['Password', 'كلمة المرور'],
    language: ['Language', 'اللغة'],
    invoice: ['Invoice', 'فاتورة'],
    hello: ['Hello', 'مرحبًا'],
    sar: ['SAR', 'ريال'],
    signout: ['Sign out', 'تسجيل خروج'],
    english: ['English', 'اللغة الإنجليزية'],
    arabic: ['Arabic', 'عربي'],
    continue: ['Continue', 'استمر'],
    please_enter_user_name: ['Please enter User Name', 'الرجاء إدخال اسم المستخدم'],
    please_enter_mobile_no: ['Please enter Mobile No', 'الرجاء إدخال رقم الجوال'],
    user_name_required: ['User name is required', 'اسم المستخدم مطلوب'],
    mobile_number_required: ['Mobile Number is required', 'رقم الجوال مطلوب'],
    mobile_number_not_valid: ['Mobile Number is not Valid', 'رقم الجوال غير صالح'],
    password_required: ['Password is required', 'كلمة المرور مطلوبة'],
    please_wait: ['Please wait', 'الرجاء الإنتظار'],
    otp: ['Otp', 'رمز الدخول'],
    otp_required: ['Otp is required', 'رمز الدخول مطلوب'],
    otp_not_valid: ['Otp is not Valid', 'رمز الدخول غير صالح'],
    number_of_access: ['Number of Access', 'عدد مرات الدخول'],
    this_week: ['this week', 'هذا  الأسبوع'],
    total_order: ['Total Order', 'مجموع  الطلبات'],
    total_price: ['Total price', 'المجموع'],
    number_of_orders: ['Number of Orders', 'عدد  الطلبات'],
    total_orders_this_week: ['Total Orders this week', 'عدد الطلبات هذا الأسبوع'],
    live_orders: ['Live Orders', 'الطلبات القائمة'],
    no_live_orders_yet: ['No Live Orders Yet', 'لا يوجد طلبات قائمة حتى الآن'],
    paid_and_expired_orders: ['Paid and Expired Orders', 'الطلبات المدفوعة و المنتهية'],
    name: ['Name', 'الأسم'],
    mobile: ['Mobile', 'رقم الجوال'],
    organization: ['Organization', 'المنشأة'],
    branch: ['Branch', 'الفرع'],
    order_type: ['Order Type', 'نوع الطلب'],
    price: ['Price', 'السعر'],
    entry_time: ['Entry Time', 'وقت الدخول'],
    exit_time: ['Exit Time', 'وقت الخروج'],
    date_and_time: ['Date and Time', 'التاريخ و الوقت'],
    status: ['Status', 'الحالة'],
    dashboard: ['Dashboard', 'لوحة البيانات'],
    organization_list: ['Organization List', 'قائمة المنشآت'],
    organizations: ['Organizations', 'المنشآت'],
    add: ['Add', 'أضف'],
    branches: ['Branches', 'الفروع'],
    group: ['Group', 'مجموعة'],
    restrooms: ['Restrooms', 'دورات المياه'],
    manager: ['Manager', 'المدير'],
    manager_mobile: ['Manager Mobile', 'جوال المدير'],
    created_at: ['Created At', 'تم إنشاءه في'],
    active: ['Active', 'نشط'],
    inactive: ['In active', 'غير نشط'],
    organization_details: ['Organization Details', 'معلومات المنشأة'],
    deactivate: ['De-activate', 'تعطيل'],
    edit: ['Edit', 'تحرير'],
    joined_at: ['Joined at', 'انضم في'],
    zakat_orders: ['Zakat Orders', 'طلبية متجر'],
    total_sales: ['Total Sales', 'مجموع  المبيعات'],
    city: ['City', 'المدينة'],
    country: ['Country', 'الدولة'],
    working_hours: ['Working Hours', 'ساعات العمل'],
    employees: ['Employees', 'الموظفين'],
    edit_organization: ['Edit Organization', 'تعديل ملف المنشأة'],
    organization_logo: ['Organization logo', 'شعار المنشأة'],
    organization_arabic: ['Organization Arabic', 'المنشأة عربي'],
    organization_english: ['Organization English', 'المنشأة بالإنجليزي'],
    organization_manager: ['Organization Manager', 'مدير المنشأة'],
    add_organization_manager: ['Add Organization Manager', 'أضف مدير منشأة'],
    cr_number: ['CR number', 'رقم السجل التجاري'],
    vat_number: ['Vat number', 'الرقم الضريبي'],
    special_percentage: ['Special percentage', 'النسبة الخاصة'],
    special_percentage_in_number: ['Special percentage in Number', 'النسبة الخاصة بالأرقام'],
    bank: ['Bank', 'البنك'],
    bank_holder_name: ['Bank holder Name', 'اسم البنك'],
    iban: ['IBAN', 'الآيبان'],
    notes: ['Notes', 'ملاحظات'],
    upload_vat_and_cr_files: ['Upload Vat and CR files', 'أرفع السجل التجاري و الرقم الضريبي'],
    make_sure_upload_file: ['Make sure you upload the file', 'تأكد آنك رفعت الملف'],
    vat_file: ['Vat file', 'ملف الضريبة'],
    cr_file: ['CR file', 'ملف السجل التجاري'],
    drag_drop_vat_file_here: ['Drag and drop Vat file here', 'قم سحب و إضافة ملف الضريبة هنا'],
    drag_drop_cr_file_here: ['Drag and drop CR file here', 'قم سحب و إضافة السجل التجاري هنا'],
    save_changes: ['Save changes', 'حفظ المتغيرات'],
    loading: ['Loading', 'قيد التحميل'],
    add_new: ['Add new', 'أضف جديد'],
    please_select_to_add: ['Please select what you want to add', 'الرجاء تحديد ما تريد إضافته'],
    create_organization: ['Create an organization', 'أنشئ متجر (منشأة)'],
    create_branch: ['Create a branch', 'أنشئ فرع'],
    create_group: ['Create a group', 'أنشئ مجموعة'],
    create_restroom: ['Create a restroom', 'أضف دورة مياه'],
    create_employee: ['Create an employee', 'أضف موظف'],
    add_organization: ['Add organization', 'أضف منشأة'],
    edit_branch: ['Edit Branch', 'تحرير فرع'],
    add_branch_manager: ['Add branch manager', 'أضف مدير فرع'],
    branch_name_arabic: ['Branch name Arabic', 'اسم الفرع – عربي'],
    branch_name_english: ['Branch name English', 'اسم الفرع – انقلش'],
    store_location: ['Store location', 'موقع المتجر'],
    select_on_the_map: ['Select on the Map', 'حدد على الخريطة'],
    sunday: ['Sunday', 'الأحد'],
    monday: ['Monday', 'الأثنين'],
    tuesday: ['Tuesday', 'الثلاثاء'],
    wednesday: ['Wednesday', 'الأربعاء'],
    thursday: ['Thursday', 'الخميس'],
    friday: ['Friday', 'الجمعة'],
    saturday: ['Saturday', 'السبت'],
    add_branch: ['Add branch', 'أضف فرع'],
    reference_id: ['Reference ID', 'الرقم المرجعي'],
    amount: ['Amount', 'المبلغ'],
    actions: ['Actions', 'الأحداث'],
    group_details: ['Group details', 'تفاصيل المجموعة'],
    group_detail: ['Group detail', 'تفاصيل المجموعة'],
    qr_logo: ['QR Logo', 'QR شعار'],
    device_id: ['Device ID', 'رقم الجهاز'],
    get_master_qr_1: ['Get Master QR 1', 'أحصل على ماستر QR1'],
    get_master_qr_2: ['Get Master QR 2', 'أحصل على ماستر QR2'],
    edit_group: ['Edit Group', 'تحرير المجموعة'],
    add_group: ['Add Group', 'أضف مجموعة'],
    group_info: ['Group Info', 'معلومات المجموعة'],
    branch_id: ['Branch ID', 'رقم الفرع'],
    floor: ['Floor', 'الدور'],
    gender: ['Gender', 'الجنس'],
    seat_type: ['Seat Type', 'نوع المقعد'],
    add_restroom: ['Add restroom', 'أضف دورة مياه'],
    edit_restroom: ['Edit Restroom', 'تحرير دورة مياه'],
    restroom_info: ['Restroom Info', 'معلومات دورة مياه'],
    cleaner: ['Cleaner', 'المنظف'],
    select: ['select', 'إختر'],
    add_cleaner: ['Add cleaner', 'أضف منظف'],
    add_devices: ['Add devices', 'أضف جهاز'],
    restroom_location: ['Restroom Location', 'موقع دورة المياه'],
    restroom_floor: ['Restroom Floor', 'دور دورة المياه'],
    restroom_gender: ['Restroom Gender', 'جنس دورة المياه'],
    male: ['Male', 'ذكر'],
    female: ['Female', 'أنثى'],
    unisex: ['Unisex', 'للجنسين'],
    paid: ['Paid', 'مدفوع'],
    yes: ['Yes', 'نعم'],
    no: ['No', 'لا'],
    western: ['Western', 'غربي'],
    add_employee: ['Add Employee', 'أضف موظف'],
    master_access: ['Master Access', 'مفتاح ماستر'],
    email: ['Email', 'البريد الإلكتروني'],
    employee_type: ['Employee Type', 'نوع  الموظف'],
    add_new_employee: ['Add new Employee', 'أضف موظف جديد'],
    please_enter_employee_info: ['Please enter employee information', 'الرجاء أدخل معلومات الموظف'],
    edit_employee: ['Edit Employee', 'تحرير معلومات الموظف'],
    users: ['Users', 'المستخدمين'],
    user: ['User', 'الموظف'],
    admin: ['Admin', 'أدمين'],
    agent: ['Agent', 'ممثل'],
    admins: ['Admins', 'الأدمين'],
    wallet: ['Wallet', 'المحفظة'],
    wallet_balance: ['Wallet balance', 'رصيد المحفظة'],
    details: ['Details', 'التفاصيل'],

    joining_date: ['Joining Date', 'تاريخ الالتحاق'],
    role: ['Role', 'دور (الوظيفي)'],
    add_admin_agent: ['Add Admin/Agent', 'أضف أدمين/ممثل'],
    edit_admin_agent: ['Edit Admin/Agent', 'عدل أدمين/ممثل'],
    please_enter_required_info: [
      'Please enter required information',
      'الرجاء إدخال المعلومات المطلوبة',
    ],
    order_details: ['Order details', 'تفاصيل الطلب'],
    update: ['Update', 'تحديث'],
    completed: ['Completed', 'مكتملة'],
    occupied: ['Occupied', 'مشغول'],
    waiting_for_payment: ['Waiting for payment', 'بانتظار الدفع'],
    payment_success: ['Payment success', 'تم الدفع بنجاح'],
    order_expired: ['Order expired', 'الطلب منتهي'],
    paid_and_expired: ['Paid and expired', 'مدفوع و منتهي'],
    order_cancelled: ['Order cancelled', 'الطلب ملغي'],
    payment_retry: ['Payment retry', 'إعادة عملية الدفع'],
    payment_failed: ['Payment failed', 'فشل عملية الدفع'],
    tkrm_order: ['TKRM order', 'طلبية تكرم'],
    zakat_order: ['ZAKAT order', 'طلبية المتجر'],
    order_no: ['Order No', 'رقم الطلب'],
    unit: ['Unit', 'الوحدة'],
    restroom_access: ['Restroom Access', 'عملية دخول دورة المياه'],
    restroom_id: ['Restroom ID', 'رقم دورة المياه'],
    recent_activities: ['Recent Activities', 'الأنشطة الحالية'],
    devices: ['Devices', 'الأجهزة'],
    // device_id: ['Device ID', 'رقم الجهاز'],
    device_secret: ['Device Secret', 'الرقم السري للجهاز'],
    device_type: ['Device Type', 'نوع الجهاز'],
    add_device: ['Add Device', 'أضف جهاز'],
    edit_device: ['Edit Device', 'تعديل جهاز'],
    add_amenity: ['Add amenity', 'أضف ملحق'],
    amenities: ['Amenities', 'مرفقات دورة المياه'],
    image: ['Image', 'صورة'],
    name_arabic: ['Name Arabic', 'الاسم عربي'],
    name_english: ['Name English', 'الاسم انجليزي'],
    edit_amenity: ['Edit Amenity', 'تعديل المرافق'],
    transactions: ['Transactions', 'العمليات'],
    transaction: ['Transaction', 'العملية'],
    transaction_details: ['Transaction Details', 'تفاصيل العملية'],
    total_commission: ['Total Commission', 'مجموع العمولة'],
    order_transaction: ['Order Transaction', 'عملية الطلب'],
    commission_transaction: ['Commission Transaction', 'العمولة'],
    zakat_transaction: ['ZAKAT Transaction', 'عملية المتجر'],
    transaction_id: ['Transaction ID', 'رقم العملية'],
    description: ['Description', 'الوصف'],
    from_name: ['From Name', 'من الأسم'],
    to_name: ['To Name', 'إلى الأسم'],
    type: ['Type', 'النوع'],
    order_id: ['Order ID', 'رقم الطلبية'],
    orders: ['Orders', 'الطلبات'],
    updated_at: ['Updated At', 'تحدثت في'],
    rating: ['Rating', 'التقييم'],
    ratings: ['Ratings', 'التقييمات'],
    notifications: ['Notifications', 'الإشعارات'],
    settings: ['Settings', 'الإعدادات'],
    saving_user_credentials: ['Saving User credentials', 'حفظ معلومات العميل'],
    collecting_user_details: [
      'Collecting User details while login',
      'حفظ معلومات العميل عن الدخول',
    ],
    on: ['On', 'مفتوح'],
    off: ['Off', 'مغلق'],
    value_added_tax: ['Value added tax', 'ضريبة القيمة المضافة'],
    of_amount: ['Of amount', 'من القيمة'],
    commission_percentage: ['Commission percentage', 'نسبة العمولة'],
    commission_details: ['Commission details', 'تفاصيل العمولة'],
    percentage: ['Percentage', 'النسبة'],
    private_chat: ['Private Chat', 'محادثة خاصة'],
    support: ['Support', 'الدعم'],
    active_chats: ['Active Chats', 'المحادثات النشطة'],
    type_a_message: ['Type a message', 'اكتب رسالة'],
    admin_chat: ['Admin Chat', 'محادثة الأدمن'],

    jan: ['Jan', 'يناير'],
    feb: ['Feb', 'فبراير'],
    mar: ['Mar', 'مارس'],
    apr: ['Apr', 'أبريل'],
    may: ['May', 'مايو'],
    jun: ['Jun', 'يونيو'],
    jul: ['Jul', 'يوليو'],
    aug: ['Aug', 'أغسطس'],
    sep: ['Sep', 'سبتمبر'],
    oct: ['Oct', 'أكتوبر'],
    nov: ['Nov', 'نوفمبر'],
    dec: ['Dec', 'ديسمبر'],
  },
}

export function getTranslation(_key, rtl) {
  const lang = rtl !== 'rtl' ? 0 : 1
  let key
  let text
  try {
    key = _key.toLowerCase().replace(/ /g, '_').replace(/\./g, '')
    text = data.resources[key][lang]
  } catch (e) {
    // console.log('error', e);
    // text = '';
    text = _key
  }
  return text
}

export function getBothTranslations(key) {
  // console.log('getBothTranslations')
  return data.resources[key]
}
