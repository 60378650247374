/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {DataService} from '../../../dataservice/dataService'
import * as Constants from '../../../config/constants'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
// import Alert from '../../../utility/components/Alert'
import {FeedBackModal} from '../../../utility/components/FeedBackModal'
import {getTranslation} from '../../../utility/Translation'
import {useLayout} from '../../../../_metronic/layout/core'

export function Login() {
  const history = useNavigate()
  const [loading, setLoading] = useState(false)
  const {languageDirection, updateLanguageDirection}: any = useLayout()

  const loginSchema = Yup.object().shape({
    mobileNo: Yup.string()
      .min(9, 'Number is not valid')
      .max(10, 'Number is not valid')
      .required(getTranslation('mobile_number_required', languageDirection)),
    userName: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(getTranslation('user_name_required', languageDirection)),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(getTranslation('password_required', languageDirection)),
  })

  const initialValues = {
    userName: '',
    mobileNo: '',
    password: '',
  }

  // const [errorState, setErrorState] = useState(false)
  const [error, setError] = useState<boolean>(false)
  const closeError = () => {
    setError(false)
  }
  const changeLanguage = () => {
    if (languageDirection === 'ltr') {
      updateLanguageDirection('rtl')
    } else {
      updateLanguageDirection('ltr')
    }
  }
  const validation = (event: any) => {
    const isValidKey = /^[0-9]$/.test(event.key)
    const isControlKey = event.key === 'Backspace' || event.key === 'Delete' || event.key === 'Tab'

    if (!isValidKey && !isControlKey) {
      event.preventDefault()
    }
  }
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      let data = {
        userName: values.userName,
        mobileNo: parseInt(values.mobileNo),
        password: values.password,
      }

      const endPoint = `auth/login?${Constants.API_KEY}`
      DataService.post(endPoint, data)
        .then((result: {data: any}) => {
          history('/auth/verify', {state: {mobileNo: values.mobileNo}})
          setLoading(false)
        })
        .catch((error: any) => {
          // console.log('wht', error.response.data.error)
          // alert(error.response.data.error)
          setError(true)
          setStatus('The login detail is incorrect')
          setSubmitting(false)
          setLoading(false)
        })
    },
  })

  return (
    <>
      {error && (
        <FeedBackModal
          openModal={error}
          closeModal={closeError}
          text={'Error in Login'}
          subText={'Please Check the credientials'}
          type={'danger'}
        />
      )}
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
          {/* begin::Logo */}
          <a href='#' className='mb-5'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/tkrm.svg')}
              className='theme-dark-show h-45px'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/tkrm.svg')}
              className='theme-light-show h-45px'
            ></img>
          </a>
          <div>{/* Please enter the Required Details */}</div>
        </div>

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {getTranslation('user_name', languageDirection)}
          </label>
          <input
            placeholder={getTranslation('user_name', languageDirection)}
            {...formik.getFieldProps('userName')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.userName && formik.errors.userName},
              {
                'is-valid': formik.touched.userName && !formik.errors.userName,
              }
            )}
            type='text'
            name='userName'
            autoComplete='off'
          />
          {formik.touched.userName && formik.errors.userName && (
            <div className='fv-plugins-message-container'>
              <span className='text-danger'>{formik.errors.userName}</span>
            </div>
          )}
        </div>
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {getTranslation('mobile_no', languageDirection)}
          </label>
          <input
            placeholder={getTranslation('mobile_no', languageDirection)}
            maxLength={12}
            {...formik.getFieldProps('mobileNo')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.mobileNo && formik.errors.mobileNo},
              {
                'is-valid': formik.touched.mobileNo && !formik.errors.mobileNo,
              }
            )}
            type='text'
            name='mobileNo'
            autoComplete='off'
            onKeyDown={(event) => {
              validation(event)
            }}
          />
          {formik.touched.mobileNo && formik.errors.mobileNo && (
            <div className='fv-plugins-message-container'>
              <span className='text-danger'>{formik.errors.mobileNo}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              {/* begin::Label */}
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                {getTranslation('password', languageDirection)}
              </label>
            </div>
          </div>
          <input
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-10'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            {getTranslation('language', languageDirection)}
          </label>

          <div className='col-lg-8 d-flex align-items-center'>
            <span
              className={clsx('badge badge-light p-2 m-1', {
                'badge-light-primary': languageDirection === 'ltr',
              })}
              onClick={changeLanguage}
              style={{cursor: 'pointer'}}
            >
              {getTranslation('english', languageDirection)}
            </span>
            <span
              className={clsx('badge badge-light p-2 m-1', {
                'badge-light-primary': languageDirection === 'rtl',
              })}
              onClick={changeLanguage}
              style={{cursor: 'pointer'}}
            >
              {getTranslation('arabic', languageDirection)}
            </span>
          </div>
        </div>
        {/* end::Form group */}
        {/* <span onClick={changeLanguage}>change language</span> */}

        {/* begin::Action */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && (
              <span className='indicator-label'>
                {getTranslation('continue', languageDirection)}
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {getTranslation('please_wait', languageDirection)}...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
      </form>
    </>
  )
}
