import React, {createContext, useContext, useState, useEffect} from 'react'
import {DefaultLayoutConfig} from './DefaultLayoutConfig'
import {
  getEmptyCssClasses,
  getEmptyCSSVariables,
  getEmptyHTMLAttributes,
  LayoutSetup,
} from './LayoutSetup'
import {
  ILayout,
  ILayoutCSSVariables,
  ILayoutCSSClasses,
  ILayoutHTMLAttributes,
} from './LayoutModels'
import {WithChildren} from '../../helpers'
import {getItem, setItem} from '../../../app/utility/localstoragecontrol'

export interface LayoutContextModel {
  config: ILayout
  classes: ILayoutCSSClasses
  attributes: ILayoutHTMLAttributes
  cssVariables: ILayoutCSSVariables
  setLayout: (config: LayoutSetup) => void
  languageDirection?: 'ltr' | 'rtl' // Add the language direction type
  setLanguageDirection?: (direction: 'ltr' | 'rtl') => void
  updateLanguageDirection?: (direction: 'ltr' | 'rtl') => void
}

const LayoutContext = createContext<LayoutContextModel>({
  config: DefaultLayoutConfig,
  classes: getEmptyCssClasses(),
  attributes: getEmptyHTMLAttributes(),
  cssVariables: getEmptyCSSVariables(),
  setLayout: (config: LayoutSetup) => {},
  languageDirection: 'ltr', // Default language direction is left-to-right (English)
  setLanguageDirection: (direction: 'ltr' | 'rtl') => {},
  updateLanguageDirection: (direction: 'ltr' | 'rtl') => {},
})

const enableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'flex')
  }
}

const disableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'none')
  }
}

const LayoutProvider: React.FC<WithChildren> = ({children}) => {
  const [config, setConfig] = useState(LayoutSetup.config)
  const [classes, setClasses] = useState(LayoutSetup.classes)
  const [attributes, setAttributes] = useState(LayoutSetup.attributes)
  const [cssVariables, setCSSVariables] = useState(LayoutSetup.cssVariables)
  const initialLanguageDirection = getItem('languageDirection') as 'ltr' | 'rtl' | null
  const [languageDirection, setLanguageDirection] = useState<'ltr' | 'rtl'>(
    initialLanguageDirection || 'ltr'
  ) // Default language direction is left-to-right (English)
  const updateLanguageDirection = (direction: 'ltr' | 'rtl') => {
    setLanguageDirection(direction)
    setItem('languageDirection', direction) // Save to local storage
  }
  const setLayout = (_themeConfig: Partial<ILayout>) => {
    enableSplashScreen()
    const bodyClasses = Array.from(document.body.classList)
    bodyClasses.forEach((cl) => document.body.classList.remove(cl))
    LayoutSetup.updatePartialConfig(_themeConfig)
    setConfig(Object.assign({}, LayoutSetup.config))
    setClasses(LayoutSetup.classes)
    setAttributes(LayoutSetup.attributes)
    setCSSVariables(LayoutSetup.cssVariables)
    setTimeout(() => {
      disableSplashScreen()
    }, 500)
  }
  const value: LayoutContextModel = {
    config,
    classes,
    attributes,
    cssVariables,
    setLayout,
    languageDirection, // Add the language direction state to the context value
    setLanguageDirection,
    updateLanguageDirection,
  }

  useEffect(() => {
    disableSplashScreen()
  }, [])

  useEffect(() => {
    // After the language direction changes, update the root HTML element
    const rootHtml = document.getElementById('root-html')
    if (rootHtml) {
      rootHtml.setAttribute('dir', languageDirection)
    }
  }, [languageDirection])

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

export {LayoutContext, LayoutProvider}

export function useLayout() {
  return useContext(LayoutContext)
}
