/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {DataService} from '../../dataservice/dataService'
import {setItem} from '../../utility/localstoragecontrol'
import {NavLink} from 'react-router-dom'
import * as Constants from '../../config/constants'
import CustomPagination from '../../utility/components/Pagination'
import {Loading} from '../../utility/components/Loading'
import {getFilterOrderType, getFilterStatus} from '../../modules/orders/components/StatusFilter'
import {useLayout} from '../../../_metronic/layout/core'
import {getTranslation} from '../../utility/Translation'
type Props = {
  className: string
}

const OrderList: React.FC<Props> = ({className}) => {
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [loadingPage, setLoadingPage] = useState<boolean>(false)
  const [isRequestPending, setIsRequestPending] = useState<boolean>(false)

  const {languageDirection}: any = useLayout()

  const [data, setData] = useState<Array<any>>([])
  const passingId = (id: any) => {
    setItem('mobile', id)
    console.log('done')
  }
  const onChange = (pageNumber: any) => {
    setPageNumber(pageNumber)
  }

  useEffect(() => {
    if (!isRequestPending) {
      setLoadingPage(true)
      setIsRequestPending(true)

      const endpoint = `order/live?${Constants.API_KEY}&limit=${10}&pageNo=${pageNumber}`
      DataService.get(endpoint)
        .then((result: {data: any}) => {
          console.log('ccc', result.data.data)
          setData(result.data.data)
          setTotalPages(result.data.totalPages)
          setPageNumber(result.data.currentPage)
          setLoadingPage(false)
          setIsRequestPending(false)
        })
        .catch((error: any) => {
          console.log('error', error)
          setLoadingPage(false)
          setIsRequestPending(false)
        })
    }
  }, [pageNumber])
  // console.log('data', data)
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-1 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            {' '}
            {getTranslation('live_orders', languageDirection)}
          </span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 orders</span> */}
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        {data.length > 0 ? (
          loadingPage ? (
            <Loading />
          ) : (
            <div className='table-responsive '>
              {/* begin::Table */}
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                {/* begin::Table head */}
                <thead>
                  <tr className='text-dark fw-bold '>
                    <th className='min-w-140px'>{getTranslation('name', languageDirection)}</th>
                    <th className='min-w-140px'>{getTranslation('mobile', languageDirection)}</th>
                    <th className='min-w-140px'>
                      {getTranslation('organization', languageDirection)}
                    </th>
                    <th className='min-w-140px'>{getTranslation('branch', languageDirection)}</th>
                    <th className='min-w-140px'>
                      {getTranslation('order_type', languageDirection)}
                    </th>
                    <th className='min-w-140px'>{getTranslation('price', languageDirection)}</th>
                    <th className='min-w-140px'>
                      {getTranslation('entry_time', languageDirection)}
                    </th>
                    <th className='min-w-140px'>
                      {getTranslation('exit_time', languageDirection)}
                    </th>
                    <th className='min-w-140px'>
                      {getTranslation('date_and_time', languageDirection)}
                    </th>
                    <th className='min-w-100px '>{getTranslation('status', languageDirection)}</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {data.map((doc, index) => (
                    <tr key={index}>
                      <td>
                        <NavLink
                          to={{
                            pathname: `/pages/order/details/${doc.orderId}`,
                          }}
                          onClick={() => {
                            passingId(doc.orderId)
                          }}
                          className='fw-bold text-muted'
                        >
                          {doc?.userName ? doc?.userName : 'N/A'}
                        </NavLink>
                      </td>
                      <td>
                        <NavLink
                          to={{
                            pathname: `/pages/order/details/${doc.orderId}`,
                          }}
                          onClick={() => {
                            passingId(doc.orderId)
                          }}
                          className='fw-bold text-muted'
                        >
                          {doc?.userMobileNo ? doc?.userMobileNo : 'N/A'}
                        </NavLink>
                      </td>
                      <td>
                        <NavLink
                          to={{
                            pathname: `/pages/order/details/${doc.orderId}`,
                          }}
                          onClick={() => {
                            passingId(doc.orderId)
                          }}
                          className='fw-bold text-muted'
                        >
                          {doc?.organizationName?.english}
                        </NavLink>
                        {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>Code: Paid</span> */}
                      </td>
                      <td>
                        <NavLink
                          to={{
                            pathname: `/pages/order/details/${doc.orderId}`,
                          }}
                          onClick={() => {
                            passingId(doc.orderId)
                          }}
                          className='fw-bold text-muted'
                        >
                          {doc?.branchName?.english}
                        </NavLink>
                      </td>
                      <td className='fw-bold text-muted'>
                        {doc?.orderType && getFilterOrderType(doc?.orderType)}
                      </td>
                      <td className='fw-bold text-muted'>
                        {doc?.totalAmount ? doc?.totalAmount : 'N/A'}
                      </td>
                      {/* <td className='fw-bold text-muted'>{doc.wallet}</td> */}
                      <td>
                        <span className='fw-bold text-muted'>{doc?.entryTime}</span>
                      </td>
                      <td>
                        <span className='fw-bold text-muted'>{doc?.exitTime}</span>
                      </td>
                      <td>
                        <span className='fw-bold text-muted'>{doc?.dateAndTime}</span>
                      </td>
                      <td>
                        <span className='badge badge-light-success'>
                          {doc?.status && getFilterStatus(doc?.status)}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
          )
        ) : data.length === 0 && !loadingPage ? (
          <div style={{textAlign: 'center', margin: 'auto', padding: '20px'}}>
            <h3>
              {' '}
              {getTranslation('no_live_orders_yet', languageDirection)}
              🙃
            </h3>
          </div>
        ) : (
          <Loading />
        )}
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
      <CustomPagination pageNumber={pageNumber} totalPages={totalPages} onChange={onChange} />
    </div>
  )
}

export {OrderList}
