/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import {useAuth} from '../core/Auth'
import {DataService} from '../../../dataservice/dataService'
import * as Constants from '../../../config/constants'
import {setItem} from '../../../utility/localstoragecontrol'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {FeedBackModal} from '../../../utility/components/FeedBackModal'
import {getTranslation} from '../../../utility/Translation'
import {useLayout} from '../../../../_metronic/layout/core'

interface State {
  mobileNo: string
}
export function OtpVerify() {
  const {languageDirection}: any = useLayout()
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const location = useLocation()
  const state = location.state as State
  const mobileNo = state.mobileNo
  const [error, setError] = useState<boolean>(false)

  const loginSchema = Yup.object().shape({
    otp: Yup.number().required(getTranslation('otp_required', languageDirection)),
  })

  const initialValues = {
    otp: '',
  }
  const closeError = () => {
    setError(false)
  }
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      let data = {
        mobileNo: parseInt(mobileNo),
        otp: parseInt(values.otp),
      }
      const endPoint = `auth/verify?${Constants.API_KEY}`
      DataService.post(endPoint, data)
        .then((result: {data: any}) => {
          const auth = result.data.user.auth
          const user = result.data.user
          saveAuth(auth)
          setCurrentUser(user)
          setLoading(false)
          setItem('access_token', result.data.token)
          setItem('user', user)
        })
        .catch((error: any) => {
          // console.log(error.response)
          // alert(error.response.data.error)
          setError(true)
          saveAuth(undefined)
          setStatus('The login detail is incorrect')
          setSubmitting(false)
          setLoading(false)
        })
    },
  })

  return (
    <>
      {error && (
        <FeedBackModal
          openModal={error}
          closeModal={closeError}
          text={'Error in verification'}
          subText={'Please Check the credientials'}
          type={'danger'}
        />
      )}
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        // id='kt_login_signin_form'
      >
        {/* begin::Form group */}
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
          {/* begin::Logo */}
          <a href='#' className='mb-5'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/tkrm.svg')}
              className='theme-dark-show h-45px'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/tkrm.svg')}
              className='theme-light-show h-45px'
            ></img>
          </a>
          <div>{/* Please enter the Required Details */}</div>
        </div>
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {getTranslation('mobile_no', languageDirection)}
          </label>
          <input
            value={mobileNo}
            name='mobileNo'
            disabled
            className={clsx('form-control form-control-lg form-control-solid is-valid')}
          />
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                {getTranslation('otp', languageDirection)}
              </label>
            </div>
          </div>
          <input
            type='number'
            autoComplete='off'
            {...formik.getFieldProps('otp')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.otp && formik.errors.otp,
              },
              {
                'is-valid': formik.touched.otp && !formik.errors.otp,
              }
            )}
          />
          {formik.touched.otp && formik.errors.otp && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.otp}</span>
              </div>
            </div>
          )}
        </div>
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && (
              <span className='indicator-label'>
                {getTranslation('continue', languageDirection)}
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {getTranslation('please_wait', languageDirection)}...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
      </form>
    </>
  )
}
