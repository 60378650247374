export function Loading() {
  const spinnerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const spinnerCircleStyle = {
    width: '10px',
    height: '10px',
    display: 'inline-block',
    border: '4px solid white', // You can change the border color
    borderTop: '4px solid transparent',
    borderRadius: '50%',
    animation: 'spin 1s linear infinite', // Create a spin animation
  }

  const keyframes = `@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }`

  return (
    <>
      <div style={spinnerStyle}>
        <button className='btn bg-primary '>
          <style>{keyframes}</style>
          <div>
            <div style={spinnerCircleStyle}></div>

            <span style={{fontSize: '15px', padding: '5px', color: 'white'}}> Processing ...</span>
          </div>
        </button>
      </div>
    </>
  )
}
