/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLayout} from '../../../core/LayoutProvider'
import {usePageData} from '../../../core/PageData'
import {useState} from 'react'

const DefaultTitle = () => {
  const {pageTitle, pageDescription, pageBreadcrumbs, button, secondButton, filterButton} =
    usePageData()

  const [selectedFilter, setSelectedFilter] = useState('')
  const setPath = (link: string) => {
    window.location.href = link
  }

  const handleFilterChange = (referenceId: any, nameEn: any) => {
    if (filterButton && filterButton.onSelect) {
      setSelectedFilter(nameEn)
      filterButton.onSelect(referenceId)
    }
  }
  const {config} = useLayout()
  return (
    <>
      <div
        data-kt-swapper='true'
        data-kt-swapper-mode='prepend'
        data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
        className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-2 pb-5 pb-lg-0'
      >
        {/* begin::Heading */}

        {pageTitle && (
          <h1 className='d-flex flex-column text-dark fw-bolder my-0 fs-1'>
            {pageTitle}
            {pageDescription && (
              <small className='text-muted fs-6 fw-bold ms-1 pt-1'>{pageDescription}</small>
            )}
          </h1>
        )}
        {/* end::Heading */}

        {pageBreadcrumbs &&
          pageBreadcrumbs.length > 0 &&
          config.pageTitle &&
          config.pageTitle.breadCrumbs && (
            <ul className='breadcrumb breadcrumb-dot fw-bold fs-base my-1'>
              {Array.from(pageBreadcrumbs)
                .filter((b) => !b.isSeparator)
                .map((item, index) => (
                  <li
                    className={clsx('breadcrumb-item', {
                      'text-dark': !item.isSeparator && item.isActive,
                      'text-muted': !item.isSeparator && !item.isActive,
                    })}
                    key={`${item.path}${index}`}
                  >
                    <Link className='text-muted' to={item.path}>
                      {item.title}
                    </Link>
                  </li>
                ))}
              <li className='breadcrumb-item text-dark'>{pageTitle}</li>
            </ul>
          )}
      </div>

      {/* second part */}
      <div className='d-flex flex-stack align-items-end gap-2'>
        <div>
          {button &&
            (button.path ? (
              <button
                type='button'
                className={button.className ? button.className : `btn btn-success`}
                onClick={() => setPath(button.path)}
              >
                {button.title}
              </button>
            ) : (
              <button
                type='button'
                className={button.className ? button.className : `btn btn-success`}
                onClick={button.click}
              >
                {button.loading ? (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  button.title
                )}

                {/* {button.title} */}
              </button>
            ))}
        </div>
        <div>
          {secondButton &&
            (secondButton.path ? (
              <button
                type='button'
                className={secondButton.className ? secondButton.className : `btn btn-primary`}
                onClick={() => setPath(secondButton.path)}
              >
                {secondButton.title}
              </button>
            ) : (
              <button
                type='button'
                className={secondButton.className ? secondButton.className : `btn btn-success`}
                onClick={secondButton.click}
              >
                {secondButton.loading ? (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  secondButton.title
                )}
                {/* {secondButton.title} */}
              </button>
            ))}
        </div>
        {filterButton && filterButton.data && (
          <div className='dropdown'>
            <button
              className='btn btn-secondary dropdown-toggle'
              type='button'
              id='filterDropdown'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              {selectedFilter !== '' ? selectedFilter : 'Filter'}
            </button>
            <ul className='dropdown-menu' aria-labelledby='filterDropdown'>
              <li>
                <a
                  className='dropdown-item'
                  href='#'
                  onClick={(e) => {
                    e.preventDefault()
                    handleFilterChange('', 'All')
                  }}
                >
                  All
                </a>
              </li>
              {filterButton.data.map((item: any) => (
                <li key={item.referenceId}>
                  <a
                    className='dropdown-item'
                    href='#'
                    onClick={(e) => {
                      e.preventDefault()
                      handleFilterChange(item.referenceId, item.nameEn)
                    }}
                  >
                    {item.nameEn}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  )
}

export {DefaultTitle}
