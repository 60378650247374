// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils/index'
import * as Constants from '../../../config/constants'
import {useThemeMode} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {DataService} from '../../../dataservice/dataService'
import {useLayout} from '../../../../_metronic/layout/core'
import {getTranslation} from '../../../utility/Translation'
type Props = {
  className: string
  chartHeight: string
  backGroundColor: string
}

const MixedWidget13: React.FC<Props> = ({className, backGroundColor, chartHeight}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {languageDirection}: any = useLayout()
  const [data, setData] = useState<any>()
  const {mode} = useThemeMode()
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear().toString().slice(-2)

  const refreshChart = (directData: any) => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartHeight, directData))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    let chart
    const endpoint = `order/access/chartData?${Constants.API_KEY}&year=${currentYear}`
    DataService.get(endpoint)
      .then((result: {data: any}) => {
        // console.log('ccc', result.data.ordersData)
        console.log('hello', result.data)
        setData(result.data)
        chart = refreshChart(result.data.ordersData)
      })
      .catch((error: any) => {
        console.log('error', error)
      })

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode])

  return (
    <div
      className={`card ${className} theme-dark-bg-body`}
      style={{backgroundColor: backGroundColor}}
    >
      {/* begin::Body */}
      <div className='card-body d-flex flex-column'>
        {/* begin::Wrapper */}
        <div className='d-flex flex-column flex-grow-1'>
          {/* begin::Title                    */}
          <a href='#' className='text-dark text-hover-primary fw-bolder fs-3'>
            {getTranslation('number_of_access', languageDirection)}
          </a>
          {/* end::Title */}

          <div
            ref={chartRef}
            className='mixed-widget-13-chart'
            style={{height: chartHeight, minHeight: chartHeight}}
          ></div>
        </div>
        {/* end::Wrapper */}

        {/* begin::Stats */}
        <div className='pt-10'>
          {/* begin::Symbol */}
          {/* <span className='text-dark fw-bolder fs-2x lh-0'>$</span> */}
          {/* end::Symbol */}

          {/* begin::Number */}
          <span className='text-dark fw-bolder fs-3x me-2 lh-0'>{data?.numOrdersThisWeek}</span>
          {/* end::Number */}

          {/* begin::Text */}
          <span className='text-dark fw-bolder fs-6 lh-0'>
            + {data?.percentageChange}% {getTranslation('this_week', languageDirection)}
          </span>
          <div className='p-2'>
            <span className='text-dark fw-bolder fs-1x '>
              ({getTranslation('total_order', languageDirection)}: {data?.totalOrders})
            </span>
          </div>
          {/* end::Text */}
        </div>
        {/* end::Stats */}
      </div>
    </div>
  )
}

const chartOptions = (chartHeight: string, chartData: any): ApexOptions => {
  const labelColor = getCSSVariableValue('--kt-gray-800')
  const strokeColor = getCSSVariableValue('--kt-gray-300') as string

  return {
    series: [
      {
        name: 'Access',
        // data: [10, 25, 1, 21, 41, 61, 41, 41, 21, 41, 21, 31],
        data: chartData.completed,
      },
    ],
    grid: {
      show: false,
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [20, 120, 120, 120],
      },
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: ['#FFFFFF'],
    },
    xaxis: {
      // categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      min: 0,
      max: 60,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return +val + ' Orders'
        },
      },
    },
    colors: ['#ffffff'],
    markers: {
      colors: [labelColor],
      strokeColor: [strokeColor],
      strokeWidth: 3,
    },
  }
}

export {MixedWidget13}
