import {useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
// import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
// import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
// import {DrawerMessenger, ActivityDrawer, InviteUsers, UpgradePlan} from '../partials'
import {MenuComponent} from '../assets/ts/components'
import {Sidebar} from './components/Sidebar'
import {useLayout} from '../../_metronic/layout/core'

const MasterLayout = () => {
  const {languageDirection} = useLayout()
  // console.log('lay', languageDirection)

  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  const [isWideScreen, setIsWideScreen] = useState(false)

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 992px)')

    const handleScreenChange = (mq: any) => {
      setIsWideScreen(mq.matches)
    }

    // Set the initial value
    setIsWideScreen(mediaQuery.matches)

    // Add listener for screen size changes
    mediaQuery.addListener(handleScreenChange)

    // Cleanup listener on component unmount
    return () => {
      mediaQuery.removeListener(handleScreenChange)
    }
  }, [])

  // Define the contentStyle for widths greater than 992px
  const contentStyle = {
    paddingTop: '50px',
    ...(isWideScreen && {
      paddingRight: languageDirection === 'rtl' ? '350px' : '0',
      paddingLeft: languageDirection === 'ltr' ? '350px' : '0',
    }),
  }

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root'>
        <div className='page d-flex flex-row flex-column-fluid'>
          <AsideDefault />
          <div className='d-flex flex-column flex-row-fluid' style={contentStyle} id='kt_wrapper'>
            <HeaderWrapper />

            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <Content>
                <Outlet />
              </Content>
            </div>
            {/* <Footer /> */}
          </div>
          <Sidebar />
        </div>
      </div>

      {/* begin:: Drawers */}
      {/* <ActivityDrawer /> */}
      {/* <RightToolbar /> */}
      {/* <DrawerMessenger /> */}
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      {/* <InviteUsers />
      <UpgradePlan /> */}
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
