export const API_KEY = 'API_KEY=D80Rq0xJh3XcTFUpB4RhYOGoV56db9kl'
export const PUBLIC_RESTROOM_LOGO = 'https://tkrm-info.s3.amazonaws.com/1721375836769-image'
export const APP_NAME = 'TKRM App"'
export const PRODUCTION_ENV = 'production'
export const LOCAL_ENV = 'local'
export const DEV_ENV = 'development'
export const ENGLISH = 'english'
export const ARABIC = 'arabic'
export const USER = 'user'
export const ADMIN = 'admin'
export const AGENT = 'agent'
export const CREDIT = 'credit'
export const DEBIT = 'debit'
export const BANKTRANSFER = 'bankTransfer'
export const REFUND = 'refund'
export const MANAGER = 'manager'
export const BRANCHMANAGER = 'branchManager'
export const CLEANER = 'cleaner'
export const WEBUSER = 'webUser'
export const APPUSER = 'appUser'
export const ORDER = 'Order'
export const IOTADMIN = 'IOTADMIN'
export const ZAKATORDER = {zakatOrder: 'ZAKAT Order'}
export const TKRMORDER = {tkrmOrder: 'TKRM Order'}
export const PAYMENT_SUCCESS = {payment_success: 'Payment Succeeded'}
export const WAITING_FOR_PAYMENT = {waiting_for_payment: 'Waiting For Payment'}
export const IN_PROGRESS = {in_progress: 'In progress'}
export const OCCUPIED = {occupied: 'Occupied'}
export const ORDER_EXPIRED = {order_expired: 'Order Expired'}
export const PAID_AND_EXPIRED = {paid_and_expired: 'Paid and Expired'}
export const ORDER_COMPLETE = {order_complete: 'Order Complete'}
export const ORDER_CANCELLED = {order_cancelled: 'Order Cancelled'}
export const PAYMENT_RETRY = {retry_payment: 'Payment Retry'}
export const PAYMENT_Failed = {payment_failed: 'Payment Failed'}
export const COMPLETED = {completed: 'Order Completed'}
export const GUEST = 'guest'
export const SCANNER = 'SCANNER'
export const CONNECT = 'CONNECT'
export const LOCK = 'LOCK'
export const ORDERPAYMENT = 'orderPayment'
export const ZAKATPAYMENT = 'zakatPayment'
export const COMMISSIONPAYMENT = 'commissionPayment'
export const WALLETPAYMENT = 'walletPayment'
export const USERWALLETPAYMENT = 'userWalletPayment'
