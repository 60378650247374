import React from 'react'
import {Modal} from 'react-bootstrap'
// import {toAbsoluteUrl} from '../../../_metronic/helpers/AssetHelpers'
import {KTSVG} from '../../../_metronic/helpers'
type Props = {
  openModal: any
  closeModal: any
  text: any
  subText: any
  type: any
}
const FeedBackModal: React.FC<Props> = ({openModal, closeModal, text, subText, type}) => {
  const divStyles = {
    padding: '150px 120px 10px 150px',
    border: '1px solid grey',
    margin: 'auto',
    backgroundColor: '#F5F8FA',
    height: '300px',
    width: '900px',
  }
  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={openModal}
      onHide={closeModal}
      backdrop={true}
    >
      <div className=' p-5' style={divStyles}>
        <div
          style={{
            marginTop: '10%',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '400px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div style={{padding: '0px 20px 20px 0px'}}>
            {type === 'success' ? (
              <KTSVG
                path='/media/icons/duotune/general/gen026.svg'
                className='svg-icon-success svg-icon-2hx'
              />
            ) : type === 'danger' ? (
              <KTSVG
                path='/media/icons/duotune/arrows/arr015.svg'
                className=' svg-icon-2hx svg-icon-danger'
              />
            ) : (
              <KTSVG
                path='/media/icons/duotune/abstract/abs019.svg'
                className='svg-icon-primary svg-icon-2hx'
              />
            )}
          </div>
          <div>
            <h1 style={{display: 'inline-block'}}>{text}</h1>
            <p className='text-muted'>{subText}</p>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export {FeedBackModal}
