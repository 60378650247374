/* eslint-disable react/jsx-no-target-blank */
// import {useIntl} from 'react-intl'
// import {KTSVG} from '../../../helpers'
// import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useLayout} from '../../../layout/core'
import {getTranslation} from '../../../../app/utility/Translation'
export function AsideMenuMain() {
  // const intl = useIntl()
  const {languageDirection}: any = useLayout()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/arrows/arr001.svg'
        // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        title={getTranslation('dashboard', languageDirection)}
        fontIcon='bi-app-indicator'
      />
      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div> */}
      <AsideMenuItem
        to='/pages/organization'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title={getTranslation('organization', languageDirection)}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/pages/public'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title={getTranslation('public_restroom', languageDirection)}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/pages/user'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title={getTranslation('users', languageDirection)}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/pages/order'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title={getTranslation('orders', languageDirection)}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/pages/device'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title={getTranslation('devices', languageDirection)}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/pages/amenities'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title={getTranslation('amenities', languageDirection)}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/pages/category'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title={getTranslation('category', languageDirection)}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/pages/transaction'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title={getTranslation('transaction', languageDirection)}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/pages/rating'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title={getTranslation('rating', languageDirection)}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/pages/wallet'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title={getTranslation('wallet', languageDirection)}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/pages/notification'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title={getTranslation('notifications', languageDirection)}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/pages/settings'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title={getTranslation('settings', languageDirection)}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/pages/support'
        title={getTranslation('support', languageDirection)}
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/arrows/arr001.svg'
      />
      {/* <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/arrows/arr001.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/arrows/arr001.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/arrows/arr001.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/arrows/arr001.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/arrows/arr001.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title='User management'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
