/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, createContext, useContext, useEffect, useState} from 'react'
import {WithChildren} from '../../helpers'

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface PageDataContextModel {
  pageTitle?: string
  setPageTitle: (_title: string) => void
  button?: any
  setButton: (_button: string) => void
  secondButton?: any
  setsecondButton: (_button: string) => void
  filterButton?: any
  setFilterButton: any
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => {},
  setButton: (_button: any) => {},
  setsecondButton: (_button: any) => {},
  setFilterButton: (_button: any) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
})

const PageDataProvider: React.FC<WithChildren> = ({children}) => {
  const [pageTitle, setPageTitle] = useState<string>('')
  const [button, setButton] = useState<any>('')
  const [secondButton, setsecondButton] = useState<any>('')
  const [filterButton, setFilterButton] = useState<any>('')
  const [pageDescription, setPageDescription] = useState<string>('')
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
    button,
    setButton,
    secondButton,
    setsecondButton,
    filterButton,
    setFilterButton,
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

type Props = {
  description?: string
  button?: any
  secondButton?: any
  filterButton?: any
  breadcrumbs?: Array<PageLink>
}

const PageTitle: FC<Props & WithChildren> = ({
  children,
  description,
  breadcrumbs,
  button,
  secondButton,
  filterButton,
}) => {
  const {
    setPageTitle,
    setPageDescription,
    setPageBreadcrumbs,
    setButton,
    setsecondButton,
    setFilterButton,
  } = usePageData()
  useEffect(() => {
    if (children) {
      setPageTitle(children.toString())
    }
    return () => {
      setPageTitle('')
    }
  }, [children])

  useEffect(() => {
    if (description) {
      setPageDescription(description)
    }
    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])

  useEffect(() => {
    if (button) {
      setButton(button)
    }
    return () => {
      setButton('')
    }
  }, [button])

  useEffect(() => {
    if (secondButton) {
      setsecondButton(secondButton)
    }
    return () => {
      setsecondButton('')
    }
  }, [secondButton])
  useEffect(() => {
    if (filterButton) {
      setFilterButton(filterButton)
    }
    return () => {
      setFilterButton('')
    }
  }, [filterButton])

  return <></>
}

const PageDescription: React.FC<WithChildren> = ({children}) => {
  const {setPageDescription} = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}

export {PageDescription, PageTitle, PageDataProvider, usePageData}
