// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils/index'
import {useThemeMode} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {DataService} from '../../../dataservice/dataService'
import * as Constants from '../../../config/constants'
import {useLayout} from '../../../../_metronic/layout/core'
import {getTranslation} from '../../../utility/Translation'
type Props = {
  className: string
  chartHeight: string
  backGroundColor: string
}

const MixedWidget14: React.FC<Props> = ({className, backGroundColor, chartHeight = '200px'}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {languageDirection}: any = useLayout()

  const [data, setData] = useState<any>()
  const {mode} = useThemeMode()
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear().toString().slice(-2)
  const refreshChart = (directData: any) => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartHeight, directData, languageDirection)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  // useEffect(() => {

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])
  useEffect(() => {
    let chart
    const endpoint = `order/admin/chartData?${Constants.API_KEY}&year=${currentYear}`
    DataService.get(endpoint)
      .then((result: {data: any}) => {
        console.log('ccc', result.data.ordersData)
        setData(result.data)
        chart = refreshChart(result.data.ordersData)
      })
      .catch((error: any) => {
        console.log('error', error)
      })

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode])

  return (
    <div
      className={`card ${className} theme-dark-bg-body`}
      style={{backgroundColor: backGroundColor}}
    >
      {/* begin::Body */}
      <div className='card-body d-flex flex-column'>
        {/* begin::Wrapper */}
        <div className='d-flex flex-column flex-grow-1'>
          {/* begin::Title                    */}
          <a href='#' className='text-dark text-hover-primary fw-bolder fs-3'>
            {getTranslation('number_of_orders', languageDirection)}
          </a>

          {/* end::Title */}

          <div
            ref={chartRef}
            className='mixed-widget-14-chart'
            style={{height: chartHeight, minHeight: chartHeight}}
          ></div>
        </div>
        {/* end::Wrapper */}

        {/* begin::Stats */}
        <div className='pt-5'>
          {/* begin::Symbol */}
          {/* <span className='text-dark fw-bolder fs-2x lh-0'>$</span> */}
          {/* end::Symbol */}

          {/* begin::Number */}
          <span className='text-dark fw-bolder fs-3x me-2 lh-0'>{data?.totalSaleThisWeek}</span>
          <span className='text-dark fw-bolder fs-2x me-2 lh-0'>
            {getTranslation('sar', languageDirection)}
          </span>
          {/* <span className='text-dark fw-bolder fs-2x lh-0'>SAR</span> */}
          {/* end::Number */}

          {/* begin::Text */}
          <span className='text-dark fw-bolder fs-6  lh-0'>
            {' '}
            {data?.percentageChange} % {getTranslation('this_week', languageDirection)}
          </span>
          <div className='p-2'>
            <span className='text-dark fw-bolder fs-1x '>
              ({getTranslation('total_orders_this_week', languageDirection)}:{' '}
              {data?.numOrdersThisWeek} )
            </span>
          </div>
          {/* end::Text */}
        </div>
        {/* end::Stats */}
      </div>
    </div>
  )
}

const chartOptions = (chartHeight: string, chartData: any, languageDirection: any): ApexOptions => {
  console.log('chartOptions', chartData)
  const labelColor = getCSSVariableValue('--kt-gray-800')

  return {
    series: [
      {
        name: 'Orders',
        // data: [1, 2.1, 1, 2.1, 4.1, 6.1, 4.1, 4.1, 2.1, 4.1, 2.1, 3.1],
        data: chartData.completed,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      height: chartHeight,
      type: 'bar',
      toolbar: {
        show: true,
      },
    },
    grid: {
      show: false,
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
    colors: ['#ffffff'],
    plotOptions: {
      bar: {
        borderRadius: 2.5,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
        columnWidth: '30%',
      },
    },
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return val + '%'
      },
      offsetY: -20,

      style: {
        fontSize: '12px',
        colors: ['#304758'],
      },
    },
    xaxis: {
      labels: {
        show: true,
      },
      categories: [
        getTranslation('jan', languageDirection),
        getTranslation('feb', languageDirection),
        getTranslation('mar', languageDirection),
        getTranslation('apr', languageDirection),
        getTranslation('may', languageDirection),
        getTranslation('jun', languageDirection),
        getTranslation('jul', languageDirection),
        getTranslation('aug', languageDirection),
        getTranslation('sep', languageDirection),
        getTranslation('oct', languageDirection),
        getTranslation('nov', languageDirection),
        getTranslation('dec', languageDirection),
        // 'Jan',
        // 'Feb',
        // 'Mar',
      ],
      position: 'top',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      show: false,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
        background: labelColor,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val + ' Orders'
        },
      },
    },
  }
}

export {MixedWidget14}
