import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {DisableSidebar} from '../../_metronic/layout/core'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const OrganizationList = lazy(() => import('../modules/organization/Organization'))
  const PublicRestroom = lazy(() => import('../modules/publicRestroom/PublicRestroom'))
  const Organization = lazy(() => import('../modules/organization/OrganizationPage'))
  const User = lazy(() => import('../modules/users/Users'))
  const Device = lazy(() => import('../modules/devices/Devices'))
  const Amenities = lazy(() => import('../modules/amenities/Amenities'))
  const Category = lazy(() => import('../modules/category/Category'))
  const Transaction = lazy(() => import('../modules/transactions/Transactions'))
  const Rating = lazy(() => import('../modules/rating/Rating'))
  const Wallet = lazy(() => import('../modules/wallet/Wallet'))
  const Notification = lazy(() => import('../modules/Notification/Notifications'))
  const Settings = lazy(() => import('../modules/settings/Settings'))
  const Order = lazy(() => import('../modules/orders/Orders'))
  const Branches = lazy(() => import('../modules/branches/Branch'))
  const Group = lazy(() => import('../modules/group/Group'))
  const Restroom = lazy(() => import('../modules/restroom/Restroom'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route
          path='/pages/organization/*'
          element={
            <SuspensedView>
              <OrganizationList />
            </SuspensedView>
          }
        />
        <Route
          path='/pages/public/*'
          element={
            <SuspensedView>
              <PublicRestroom />
            </SuspensedView>
          }
        />
        <Route
          path='/pages/user/*'
          element={
            <SuspensedView>
              <User />
            </SuspensedView>
          }
        />
        <Route
          path='/pages/device/*'
          element={
            <SuspensedView>
              <Device />
            </SuspensedView>
          }
        />
        <Route
          path='/pages/amenities/*'
          element={
            <SuspensedView>
              <Amenities />
            </SuspensedView>
          }
        />
        <Route
          path='/pages/category/*'
          element={
            <SuspensedView>
              <Category />
            </SuspensedView>
          }
        />
        <Route
          path='/pages/transaction/*'
          element={
            <SuspensedView>
              <Transaction />
            </SuspensedView>
          }
        />
        <Route
          path='/pages/rating/*'
          element={
            <SuspensedView>
              <Rating />
            </SuspensedView>
          }
        />
        <Route
          path='/pages/wallet/*'
          element={
            <SuspensedView>
              <Wallet />
            </SuspensedView>
          }
        />
        <Route
          path='/pages/notification/*'
          element={
            <SuspensedView>
              <Notification />
            </SuspensedView>
          }
        />
        <Route
          path='/pages/settings/*'
          element={
            <SuspensedView>
              <Settings />
            </SuspensedView>
          }
        />
        <Route
          path='/pages/user/*'
          element={
            <SuspensedView>
              <User />
            </SuspensedView>
          }
        />
        <Route
          path='/pages/order/*'
          element={
            <SuspensedView>
              <Order />
            </SuspensedView>
          }
        />
        <Route
          path='/branches/*'
          element={
            <SuspensedView>
              <Branches />
            </SuspensedView>
          }
        />
        <Route
          path='/restroom/*'
          element={
            <SuspensedView>
              <Restroom />
            </SuspensedView>
          }
        />
        <Route
          path='/group/*'
          element={
            <SuspensedView>
              <Group />
            </SuspensedView>
          }
        />
        <Route
          path='/organization/*'
          element={
            <SuspensedView>
              <Organization />
            </SuspensedView>
          }
        />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='pages/support/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  )
}

export {PrivateRoutes}
